@import 'src/style/variables';

.dashboard-wrapper {
  padding-top: 1em;
  padding-bottom: 3em;
}

div.dashboard-header {
  margin-top: $pageHeaderMargin * 1.2;
}
