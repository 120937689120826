@import 'src/style/variables';

.koi-facade {
  padding: $pageMargin;

  .header {
    margin-top: $pageHeaderMargin;
  }

  .page-item {
    margin-top: $pageItemMargin;
  }
}
