@import "src/style/variables";

.koi-sh-page {
  .font-bigger {
    .MuiTypography-caption {
      font-size: 1.5em;
    }

    .MuiTypography-h5 {
      font-size: 2em;
    }
  }
}
