@import "src/style/variables";

.koi-ppv-page {
  .parts-table {
    table {
      table-layout: fixed;
      //min-height: 59em;
    }

    .col-s__id {
      width: 1.2em;
    }

    .col-item_id {
      width: 1.1em;
    }

    .col-p__description {
      width: 4.5em;
    }

    .col-pv_opportunity {
      width: 1.7em;
    }

    .col-p__spend__sum {
      width: 1.5em;
    }

    .col-p__unit_cost__min {
      width: 1.35em;
    }

    .col-p__unit_cost__avg {
      width: 1.35em;
    }

    .col-group_size {
      width: 0.5em;
    }

    .col-p__quantity__sum {
      width: 0.9em;
    }

    margin-bottom: $pageItemMargin;
  }
}
