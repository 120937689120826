svg.tree-graph {
  .clickable {
    cursor: pointer;
  }

  circle {
    //fill: #fff;
    stroke: black;
    stroke-width: 1px;
  }

  text {
    font: 11px sans-serif;
  }

  .link {
    fill: none;
    stroke: #ccc;
    stroke-width: 2px;
  }

  .legend-header {
    font-weight: bold;
  }

  .legend-item text {
    font-size: 18px;
  }
}
