@import "src/style/colors";

.data-table.alt-rows {
  tr:nth-of-type(odd) {
    background: $colorPartsTableRow1;
  }

  tr:nth-of-type(even) {
    background: $colorPartsTableRow2;
  }

  tr.MuiTableRow-head {
    background: $colorPartsTableHead;

    .MuiTableCell-head {
      color: $colorPartsTableHeadFont;
    }
  }
}
