.slope-graph-chart {
  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .slope-line {
    stroke-width: 3px;
    stroke-linecap: round;
  }

  .slope-label-left, .slope-label-right {
    font-size: 16px;
    cursor: default;
    font-weight: 400;
  }

  .label-figure {
    font-weight: 700;
  }

  .border-lines {
    stroke: #999;
    stroke-width: 1px;
  }

  .voronoi path {
    fill: none;
    pointer-events: all;
  }

  circle {
    //fill: white;
    //stroke: black;
    //stroke-width: 2px;
  }
}
