.boxplot {
  .pointer-events {
    pointer-events: all;
  }

  .clickable {
    cursor: pointer;
  }

  .hover-overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
    //stroke-width: 2px;
  }

  g.box-wrapper {
    pointer-events: all;

    &:hover {
      .hover-overlay {
        fill: lightgrey;
      }

      rect.bar {
        fill: black;
      }
    }
  }

  .data {
    .backbone, .line {
      stroke: black;
    }

    .box {
      stroke: black;
    }
  }

  .zero-axis line {
    stroke: red;
  }
}
