.drop-file-component {
  margin: 50px auto;
  height: 263px;
  width: 425px;
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
