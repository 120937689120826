@import "src/style/variables";

.koi-sc-page {
  .MuiStepper-root {
    margin-top: 2em;
  }

  .page-item {
    margin-top: 2em;
  }
}
