#main-header {
  .mithra-logo {
    //width: 180px;
    //height: 40px;
    width: 135px;
    height: 30px;
    line-height: 40px;
    font-size: 40px;
  }

  .MuiFormLabel-root {
    color: inherit;
  }
}

#main-footer {
}
