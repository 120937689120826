@import "src/style/variables";
@import "src/style/colors";

.koi-facade.koi-spend-concentration-page {
  div.intro {
    max-width: 43em;
  }

  .bar-chart {
    g.cat-axis, g.val-axis {
      text {
        font-size: 14px;
      }
    }
  }

  .key-value-container {
    .key-value {
      height: 1.6em;
    }
  }

  .pareto-chart {
    g.bar-value-axis {
      text {
        font-size: 14px;
      }
    }
  }

  .parts-table {
    table {
      table-layout: fixed;
      //min-height: 59em;

    }

    .col-p__spend {
      width: 1em;
    }

    .col-p__quantity {
      width: 1em;
    }

    .col-p__description {
      width: 4em;
    }

    .col-p__id {
      width: 1em;
    }

    margin-bottom: $pageItemMargin;
  }
}
