div.dashboard-card.spend-concentration {

}

div.dashboard-card {
  &.help-opened {
    background-color: #f5f5f5;
  }

  .MuiCardContent-root {
    min-height: 9em;
  }

  .MuiCardHeader-title {
    font-size: 1.7em;
  }

  //background-color: red;
  .highlight {
    font-weight: bold;
  }
}

div.dashboard-card-popout {
  max-width: 21em;
  padding: 2em;
}
