@import 'src/style/variables';

.home-page {
  > h1,
  > p {
    margin-top: $pageHeaderMargin;
  }

  a {
    margin-left: 0.2em;
  }
}
