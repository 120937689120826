@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/utilities";

@import "src/components/main/main";
@import "src/components/debug-components/debug-components";
@import "jobs/config/configure";
@import "src/auth/auth";

.main-paper.MuiPaper-root {
  padding: 0.5em;
  min-height: 50em;
}

.main-paper .loading-spinner {
  margin-top: 21em;
  text-align: center;
}

.fill-content {
  height: 19em; // depr
}

.pre-viz {
  margin-bottom: 2em;

  .MuiTabs-root {
    margin-bottom: 2em;
  }
}

.pre-viz-settings {
  width: 60em;
  margin: 0 auto;
}

a.button {
  text-decoration: none;
}

a.file-button {
  text-decoration: none;

  button {
    text-transform: none;
    text-decoration: underline;
  }
}

@import "utils";
